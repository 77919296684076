import { RouteMap } from '@common/RouteMap';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import { ReactComponent as ToastCloseIcon } from '@images/Close.svg';
import classNames from 'classnames';
import React from 'react';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);
  const Toast = {
    addWishlist: {
      message: 'Product has been successfully added to your wishlist.',
      button: 'Go to Wishlist',
      redirect: RouteMap.ACCOUNT_WISHLIST,
    },
    removeWishlist: {
      message: 'Product has been successfully removed from your wishlist.',
      button: 'Go to Wishlist',
      redirect: RouteMap.ACCOUNT_WISHLIST,
    },
    addedToCart: {
      message: 'Product has been successfully added to your bag.',
      button: 'Go to Bag',
      redirect: RouteMap.CART,
    },
  };

  return (
    <div
      className={classNames('', {
        'container relative w-full flex mx-auto justify-center': !quickView,
        'w-full flex mx-auto justify-center': quickView,
      })}
    >
      <div className="absolute top-0 z-9999 max-w-680 md:min-w-680">
        {toasts.map((toast) =>
          toast.type === 'invalidCouponCode' ? (
            <></>
          ) : Toast[toast?.type] ? (
            <div className="relative w-full">
              <div
                key={toast.key}
                className={classNames('shadow-toastShadow border border-border mt-10 md:mt-34 bg-white p-16')}
              >
                <div className="flex w-full items-center text-16 text-heading justify-between">
                  <div className="flex w-full flex-wrap gap-8" font-customisation="para-text">
                    <p className="min-w-fit flex-1">{Toast[toast.type]['message']}</p>
                    <a href={Toast[toast.type]['redirect']} className="text-theme min-w-fit">
                      {Toast[toast.type]['button']}
                    </a>
                  </div>
                  <ToastCloseIcon
                    className="cursor-pointer min-w-12 min-h-12 ml-30"
                    onClick={() => removeToast(toast.key)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              key={toast.key}
              className={classNames('shadow-toastShadow mt-10 border border-border', {
                'bg-errorToast': toast.variant === 'bg-toast-error',
                'bg-successToast': toast.variant !== 'bg-toast-error',
              })}
            >
              <div className="flex w-full items-center p-20 text-14 font-semibold text-white justify-between">
                <div font-customisation="para-text" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                <ToastCloseIcon
                  className="cursor-pointer min-w-12 min-h-12 ml-30"
                  onClick={() => removeToast(toast.key)}
                />
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default Toasts;
