import { ReactComponent as ArrowSmall } from '@images/ArrowSmall.svg';
import classNames from 'classnames';
import React, { useState } from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  const [hover, setHover] = useState(false);

  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={classNames(
            `w-fit relative bg-theme font-medium block text-white leading-24 flex justify-center items-center items-center min-h-40 py-12 px-24 transition-all duration-150 text-center text-16 border border-transparent ${customClass}`,
            {
              'pr-52': hover,
            },
          )}
          target="_blank"
          rel="noreferrer"
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          <p> {text}</p>
          <ArrowSmall
            className={classNames('block absolute right-20 opacity-0 h-16 w-16 transition-all duration-200', {
              'opacity-100': hover,
            })}
          />
        </a>
      ) : null}
    </>
  );
};

export default Cta;
