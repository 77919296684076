import StoreInfo from '@common/models/StoreInfo';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import { context } from '@common/utils/constants';
import { getBuyerJWTToken } from '@common/utils/token';
import Address from '@images/Address.svg';
import Email from '@images/EmailFooter.svg';
import Facebook from '@images/FacebookFooter.svg';
import Instagram from '@images/Instagram.svg';
import Linkedin from '@images/Linkedin.svg';
import Phone from '@images/Phone-call.svg';
import Pinterest from '@images/Pinterest.svg';
import Twitter from '@images/TwitterFooter.svg';
import Youtube from '@images/Youtube.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';

interface FooterProps {
  storeInfo: StoreInfo;
  customPages: CustomPagesSliceType;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const quickLinks =
    'block text-center md:text-left w-full w-fit mb-16 last:mb-0 text-white text-opacity-80 text-14 font-normal ';
  const header = 'text-center md:text-left text-white text-18 mb-24 font-medium';
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div
      className="w-full text-white text-14"
      style={{
        backgroundColor: `#0C3138`,
      }}
      color-customisation="footer-bg"
      id="page-footer"
    >
      <div className="w-full container mx-auto py-32 md:py-60">
        <Link
          to="/"
          className="flex justify-center md:justify-start mx-auto mb-40 overflow-hidden"
          id="mobile-footer-logo"
        >
          {pageHeaderLayout?.logo ? (
            <Image
              noDefaultDimensions
              src={pageHeaderLayout?.logo}
              alt={storeInfo?.storename}
              className="overflow-hidden object-contain h-44"
            />
          ) : (
            <div className="font-medium text-20 text-white">{storeInfo?.storename}</div>
          )}
        </Link>
        <div className="md:justify-between justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-1 md:px-4">
            <div className="pb-24 md:pb-0 border-b border-white border-opacity-20 md:border-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Policies
              </div>
              {arePoliciesPresent && !policyTos?.cancelAndRefund && (
                <Link
                  to="/policy/cancellation"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-cancellation-refund"
                >
                  Cancellation &amp; Refund
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.termsAndConditions && (
                <Link
                  to="/policy/tnc"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-terms-conditions"
                >
                  Terms & Conditions
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
                <Link
                  to="/policy/shipping"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-shipping-delivery"
                >
                  Shipping &amp; Delivery
                </Link>
              )}
              {arePoliciesPresent && !policyTos?.privacy && (
                <Link
                  to="/policy/privacy"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-privacy"
                >
                  Privacy
                </Link>
              )}
            </div>
            <div className="py-24 md:py-0 border-b border-white border-opacity-20 md:border-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Account
              </div>
              {features?.features?.buyerLogin && getBuyerJWTToken() && (
                <Link
                  to="/account/profile"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-profile"
                >
                  Profile
                </Link>
              )}
              <Link
                to="/track-order"
                className={quickLinks}
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-track-order"
              >
                Track Order
              </Link>
              {features?.features?.customPages ? (
                <>
                  {getActiveNavbar('About Us', filterBar) && (
                    <Link
                      to="/aboutus"
                      className={quickLinks}
                      font-customisation="para-text"
                      color-customisation="footer-links"
                      id="footer-about-us"
                    >
                      About Us
                    </Link>
                  )}
                </>
              ) : null}
              {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                <Link
                  to="/contactus"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-contact-us"
                >
                  Contact Us
                </Link>
              ) : null}
              {features?.features?.customPages ? (
                <>
                  {getActiveNavbar('FAQs', filterBar) && (
                    <Link
                      to="/faqs"
                      className={quickLinks}
                      font-customisation="para-text"
                      color-customisation="footer-links"
                      id="footer-faqs"
                    >
                      FAQs
                    </Link>
                  )}
                </>
              ) : null}
            </div>
            <div className="py-24 md:py-0 border-b border-white border-opacity-20 md:border-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Company
              </div>
              {features?.features?.customPages ? (
                <>
                  {getActiveNavbar('About Us', filterBar) && (
                    <Link
                      to="/aboutus"
                      className={quickLinks}
                      font-customisation="para-text"
                      color-customisation="footer-links"
                      id="footer-about-us"
                    >
                      About Us
                    </Link>
                  )}
                </>
              ) : null}
              {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                <Link
                  to="/contactus"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-contact-us"
                >
                  Contact Us
                </Link>
              ) : null}
              {features?.features?.customPages ? (
                <>
                  {getActiveNavbar('FAQs', filterBar) && (
                    <Link
                      to="/faqs"
                      className={quickLinks}
                      font-customisation="para-text"
                      color-customisation="footer-links"
                      id="footer-faqs"
                    >
                      FAQs
                    </Link>
                  )}
                </>
              ) : null}
            </div>
            <div className="py-24 md:py-0">
              <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                Contact Us
              </div>
              <div className="mx-auto">
                {storeInfo?.contactInfo?.number ? (
                  <a
                    className="flex justify-center items-center flex-col md:flex-row md:justify-start md:items-start mb-16"
                    href={`tel:${storeInfo?.contactInfo?.number}`}
                    id="footer-contact-number"
                    font-customisation="para-text"
                    color-customisation="footer-links"
                  >
                    <Image noDefaultDimensions={true} src={Phone} alt="Phone" className="mr-10 h-24 w-24 min-w-24" />
                    <p className="text-center md:text-left text-white opacity-80 text-14 font-normal">
                      {storeInfo?.contactInfo?.number}
                    </p>
                  </a>
                ) : null}
                {storeInfo?.contactInfo?.email ? (
                  <a
                    href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                      storeInfo?.contactInfo?.email
                    }`}
                    className="flex justify-center items-center flex-col md:flex-row md:justify-start md:items-start mb-16"
                    font-customisation="para-text"
                    color-customisation="footer-links"
                  >
                    <Image noDefaultDimensions={true} src={Email} alt="Email" className="mr-10 h-24 w-24 min-w-24" />
                    <p
                      className="break-all text-center md:text-left text-white opacity-80 text-14 font-normal"
                      id="footer-contact-mail"
                    >
                      {storeInfo?.contactInfo?.email}
                    </p>
                  </a>
                ) : null}
                {storeInfo?.contactInfo?.address ? (
                  <p
                    className="flex flex-col justify-center items-center md:flex-row md:justify-start md:items-start"
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-contact-address"
                  >
                    <Image
                      noDefaultDimensions={true}
                      src={Address}
                      alt="Address"
                      className="mr-10 h-24 w-24 min-w-24"
                    />
                    <p className="text-center md:text-left text-white opacity-80 text-14 font-normal break-all">
                      {storeInfo?.contactInfo?.address}
                    </p>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-32 flex flex-col md:flex-row justify-between items-center">
          <div>
            {storeInfo?.hasSocialLinks ? (
              <div className="w-full flex items-center justify-center md:justify-start flex-no-wrap mb-16 md:mb-0">
                {storeInfo?.social?.facebook ? (
                  <a
                    href={storeInfo?.social?.facebook}
                    title="Facebook"
                    target="_black()"
                    className="w-24 h-24 flex justify-center items-center"
                  >
                    <Image
                      noDefaultDimensions={true}
                      className="w-24 h-24"
                      id="mobile-footer-facebook-link"
                      src={Facebook}
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.twitter ? (
                  <a
                    href={storeInfo?.social?.twitter}
                    title="Twitter"
                    target="_black()"
                    className="w-24 h-24  ml-16 flex justify-center items-center"
                  >
                    <Image
                      noDefaultDimensions={true}
                      className="w-24 h-24 "
                      id="mobile-footer-twitter-link"
                      src={Twitter}
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.instagram ? (
                  <a
                    href={storeInfo?.social?.instagram}
                    title="Instagram"
                    target="_black()"
                    className="w-24 h-24  ml-16 flex justify-center items-center"
                  >
                    <Image
                      noDefaultDimensions={true}
                      className="w-24 h-24"
                      id="mobile-footer-instagram-link"
                      src={Instagram}
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.pinterest ? (
                  <a
                    href={storeInfo?.social?.pinterest}
                    title="Pinterest"
                    target="_black()"
                    className="w-24 h-24  ml-16 flex justify-center items-center"
                  >
                    {/* <PinterestFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-10  h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="w-24 h-24"
                      id="mobile-footer-pinterest-link"
                      src={Pinterest}
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.youtube ? (
                  <a
                    href={storeInfo?.social?.youtube}
                    title="YouTube"
                    target="_black()"
                    className="w-24 h-24  ml-16 flex justify-center items-center"
                  >
                    {/* <YoutubeFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24  h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="w-24 h-24"
                      id="mobile-footer-youtube-link"
                      src={Youtube}
                    />
                  </a>
                ) : null}
                {storeInfo?.social?.linkedin ? (
                  <a
                    href={storeInfo?.social?.linkedin}
                    title="LinkedIn"
                    target="_black()"
                    className="w-24 h-24   md:mr-34 flex justify-center items-center"
                  >
                    {/* <LinkedinFooter className="md:w-24 md:h-24 md:mr-24 w-18 mr-24 h-18" /> */}
                    <Image
                      noDefaultDimensions={true}
                      className="w-24 h-24"
                      id="mobile-footer-linkedin-link"
                      src={Linkedin}
                    />
                  </a>
                ) : null}
              </div>
            ) : null}
            {/* <InstamojoLogo /> */}
          </div>
          <span
            className={`text-14 text-greyGreen text-center`}
            font-customisation="para-text"
            color-customisation="footer-heading"
          >
            ©{context.MERCHANT_HOSTNAME} {new Date().getFullYear()}
            <span className="text-20 mx-8 font-bold h-4 w-4 bg-greyGreen rounded-full inline-block mb-2"></span>
            <span>All rights reserved</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default connect(({ pageHeader }: RootState) => ({ pageHeader }))(Footer);
